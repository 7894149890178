<template lang="pug">
router-link.blog-card(:to="$path.blogArticle(article.slug)")
  v-img.blog-card__image(v-if="article.preview_image" :src="article.preview_image")
  .pa-6
    .t-caption-small.c-text--dimmed.mb-3 {{ article.published_at | date }}
    .mb-7
      .t-h3-mobile.mb-3.c-black100 {{ article.title }}
      .t-normal.c-text(v-if="article.preview_text") {{ article.preview_text }}
    .d-flex
      a.blog-card__video.c-black100(v-if="article.video_url" :src="article.video_url")
        ui-icon(name="play")
      .blog-card__type.c-text {{ article.kind_text }}
</template>

<script>
  import { UiIcon } from '@/uikit';

  export default {
    name: 'blog-card',
    components: { UiIcon },
    props: {
      article: { type: Object, required: true },
    },
  };
</script>

<style lang="scss">
  .blog-card {
    display: block;
    box-shadow: shadow('xsmall', 'light');
    border-radius: 4px;
    background: c('white', 'light');
    text-decoration: none;
    margin-bottom: 16px;

    &__image {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &__video {
      border-radius: 50%;
      border: 1px solid c('black10', 'light');
      margin-right: 10px;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__type {
      border-radius: 3px;
      border: 1px solid c('black10', 'light');
      padding: 6px 12px;
    }
  }
</style>
