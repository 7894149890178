<template lang="pug">
app-layout
  .page-article(v-if="article")
    .page-article__toolbar.px-2.mb-6.mx-n4
      ui-btn.px-0(text :to="$path.blog()" width=60)
        ui-icon.c-black(name="arrow-left" left)
        .c-text Блог
    .t-caption-small.c-text--dimmed.mb-4 {{ article.published_at | dateFull }}
    .t-h1-mobile.mb-8 {{ article.title }}
    v-img.page-article__image(v-if="article.preview_image" :src="article.preview_image")
    .mt-8.mb-2(v-if="article.body" v-html="article.body")
    .mb-8(v-if="article.as_is" v-html="article.as_is")

    .page-article__nav-block
      ui-btn.pl-0.c-gray80(v-if="article.prev_id" text :to="$path.blogArticle(article.prev_id)")
        ui-icon.c-black(name="arrow-left" left)
        .t-caption-small.c-text Предыдущая
      ui-btn.pr-0.c-gray80.ml-auto(
        v-if="article.next_id"
        text
        :to="$path.blogArticle(article.next_id)"
      )
        .t-caption-small.c-text Следующая
        ui-icon.c-black(name="arrow-right" right)

    .page-article__others
      .t-h2-mobile.mb-6 Другие статьи

      blog-card(
        v-for="item in article.another_articles"
        :key="item.id"
        v-if="item"
        :article="item"
      )
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import { UiIcon, UiBtn } from '@/uikit';

  import { service as API } from '@/services/pages';
  import { handleError } from '@/libs/handle-error';

  import BlogCard from '@/components/BlogCard.vue';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageArticle', 'blue');

  export default {
    name: 'page-article',
    components: { UiIcon, UiBtn, AppLayout, BlogCard },

    props: {
      articleId: { type: String, required: true },
    },
    data() {
      return {
        article: undefined,
      };
    },

    mounted() {
      this.fetchArticle();
    },

    methods: {
      async fetchArticle() {
        try {
          this.loading = true;
          this.article = await API.articles(this.articleId);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-article {
    padding: 28px 16px;

    &__toolbar {
      display: flex;
      align-items: center;
    }

    & &__image {
      max-width: 110%;
      margin: 0 -16px;
    }

    blockquote {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 4px solid c('black40', 'light');
    }

    .attachment {
      img {
        width: 100%;
        height: 100%;
      }
      &__caption {
        @include text--small;
        margin-top: 6px;
        color: c('text--dimmed', 'light');
      }
    }

    &__nav-block {
      padding: 8px 0;
      margin-bottom: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid c('gray10', 'light');
      border-bottom: 1px solid c('gray10', 'light');
    }

    figure img {
      width: 100%;
      height: 100%;
    }
  }
</style>
